module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-C8THF3K37F"],"gtagConfig":{"optimize_id":"G-C8THF3K37F","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"exclude":["http://localhost**"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0,"respectDNT":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
